
  import { Watch, Component, Vue, Ref, InjectReactive } from "vue-property-decorator";

  import { bookDeviceWithDay, bookDeviceClassData, listBookStoreByType } from "@/api/booking";

  import { debounce } from "lodash";

  import * as echarts from "echarts";
  import dayjs from "@/utils/day";

  const seriesItem = {
    name: '可预约总量',
    type: 'line',
    smooth: true,
    showAllSymbol: true, //显示所有图形。
    //标记的图形为实心圆
    symbolSize: 8, //标记的大小
    itemStyle: {
        //折线拐点标志的样式
        color: 'white',
        borderWidth: '2',
        normal: {
            color: '#566fc4'//拐点颜色
        }
    },
    label: {
        show: true,
        position: 'top',
        color: "#566fc4"
    },
    lineStyle: {
        color: '#566fc4'
    },
    data: [],
}
  
  @Component({})
  export default class BookingCountDeviceChart extends Vue {

    @Ref("dayCountChartRef") dayCountChartRef: HTMLElement;

    @InjectReactive() storeList;

    chart = null;

    dayRecords = [];

    storeId = "";

    legendList = [];

    storeSelectLoading = false;
    storeOptions = [];

    timeType = 0; // 0-周 1-月 2-自定义时间

    options = [
      { value: 1, label: "一月" },{ value: 2, label: "二月" },{ value: 3, label: "三月" },{ value: 4, label: "四月" },
      { value: 5, label: "五月" },{ value: 6, label: "六月" },{ value: 7, label: "七月" },{ value: 8, label: "八月" },
      { value: 9, label: "九月" },{ value: 10, label: "十月" },{ value: 11, label: "十一月" },{ value: 12, label: "十二月" },
    ]
    month: number|string = "";
    times = [];

    chartOptions = {
        backgroundColor: 'white',
        grid: {
            top: '15%',
            left: '3%',
            right: '5%',
            bottom: '35',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            borderWidth: 1,
            axisPointer: {
            type: 'shadow'
            },
            extraCssText: 'z-index:2',    
            formatter() {},        
        },
        legend: [{
            bottom: "0",
            orient: 'horizontal',
            data: this.legendList,
            itemWidth: 30,
            itemHeight: 10,
            itemGap: 15,
            borderRadius: 4,
            textStyle: {
                color: '#000',
                fontFamily: 'Alibaba PuHuiTi',
                fontSize: 14,
                fontWeight: 400
            }
        }],
        xAxis: {
            name: "时间",
            type: 'category',
            nameTextStyle: {
                color: '#999',
                fontSize: 14,
            },
            data:[],
            axisLine: {
            show: false
            },
            axisTick: {
            show: false
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#393939' //X轴文字颜色
                }
            }
        },
        yAxis: [
            {
            type: 'value',
            name: "数量",
            nameTextStyle: {
                color: '#999',
                fontSize: 14,
            },
            nameGap: 30,  // 表现为上下位置
            axisLine: {
                show: true,
                lineStyle: {
                color: '#eeeeee'
                }
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                color: '#393939',
                fontSize: 14
            },
            splitLine: {
                show: true,
                lineStyle: {
                color: '#eeeeee'
                }
            }
            }
        ],
        series: [
        ]
    }

    daterange = {
        maxTime: "",
        minTime: "",
        max: 29,
    }
    pickerOptions = {
        onPick: time => {
        if (!time.maxDate) {
            let timeRange = this.daterange.max * 24 * 60 * 60 * 1000; 
            // @ts-ignore
            this.daterange.minTime = time.minDate.getTime() - timeRange; 
            this.daterange.maxTime = time.minDate.getTime() + timeRange;
        } else {
            this.daterange.maxTime = "";
            this.daterange.minTime = "";
        }
        },
        disabledDate: time => {
        if (this.daterange.minTime && this.daterange.maxTime) {
            return (
            time.getTime() < this.daterange.minTime ||
            time.getTime() > this.daterange.maxTime
            );
        }
        }
    }

    mounted() {
        this.weekChange();
    }

    storeIdChange() {
        this.getChartsData(this.timeType);
    }

    weekChange() {
      this.timeType = 0;
      this.month = undefined;
      this.times = [];
      this.getChartsData(this.timeType);
    }

    monthChange($event) {
      if (!$event) return;
      this.timeType = 1;
      this.times = [];
      this.getChartsData(this.timeType);
    }

    timesChange($event) {
      if (!$event?.length) return;
      this.timeType = 2;
      this.month = "";
      this.getChartsData(this.timeType);
    }

    getChartsData(value) {
      const param = {
        storeIds: this.storeId ? [this.storeId] : undefined,
        startDate: "",
        endDate: "",
      };
      if (value == 0) {
        const weekStart = dayjs().startOf('isoWeek');
        const weekEnd = dayjs().endOf('isoWeek');
        const startDate = weekStart.format("YYYY-MM-DD 00:00:00");
        const endDate = weekEnd.format("YYYY-MM-DD 23:59:59");
        Object.assign(param, { startDate, endDate })
      }
      if (value == 1) {
        const startDate = dayjs().set('month', this.month as number - 1).startOf("month").format("YYYY-MM-DD 00:00:00");
        const endDate = dayjs().set('month', this.month as number - 1).endOf("month").format("YYYY-MM-DD 23:59:59");
        Object.assign(param, { startDate, endDate })
      }
      if (value == 2) {
        const [start, end] = this.times;
        const startDate = dayjs(start).format("YYYY-MM-DD 00:00:00");
        const endDate = dayjs(end).format("YYYY-MM-DD 23:59:59");
        Object.assign(param, { startDate, endDate })
      }
      this.getChartData(param)
    }

    getChartData(param = {}) {
        bookDeviceClassData(param).then((res2) => {
            const list = res2.data;
            const colors = ["#34bfb3", "#75bd49", "#f1bc2a", "#ed853b", "#4b72c9","#8CFF40", "#4050FF", "#409CFF"]
            this.legendList = list.map((el, index) => ({ ...el, name2: `${el.name}(设备数: ${el.num})`, color: colors[index] }));
            this.chartOptions.legend[0].data = this.legendList.map((el) => el.name2);
        }).then(() => {
            bookDeviceWithDay(param).then((res) => {
                const { ableBookList, dayRecords, recordList } = res.data;

                this.dayRecords = dayRecords;
                this.chartOptions.xAxis.data = dayRecords.map((el) => el.dateStr);

                const dataList = []; // 与legendList 对应legendList 顺序
                const chartDataList = [];
                // 循环每一天，deviceRecords 是一个数组，里面是所有设备
                dayRecords.forEach((res, index) => {
                    dataList[index] = [];
                    this.legendList.forEach((leg, legIndex) => {
                        const record = res.deviceRecords.find((dev) => dev.deviceClassName == leg.name);
                        dataList[index].push(record?.num ?? 0)
                    })
                })
                this.legendList.forEach((ll, llIndex) => {
                    chartDataList[llIndex] = [];
                    dataList.forEach((dl) => {
                        chartDataList[llIndex].push(dl[llIndex]);
                    })
                })

                this.chartOptions.series = [
                    ...chartDataList.map((cl, clIndex) => ({
                        name: this.legendList[clIndex]?.name2,
                        type: 'line',
                        smooth: true,
                        showAllSymbol: true, //显
                        data: cl,
                        symbolSize: 8,
                        itemStyle: {
                            //折线拐点标志的样式
                            color: 'white',
                            borderWidth: '2',
                            normal: {
                                color: this.legendList[clIndex]?.color
                            }
                        },
                        label: {
                            show: true,
                            position: 'top',
                            color: this.legendList[clIndex]?.color
                        },
                        lineStyle: {
                            color: this.legendList[clIndex]?.color
                        },
                    }))
                ]

                const vm = this;
                this.chartOptions.tooltip = {
                    ...this.chartOptions.tooltip,
                    // @ts-ignore
                    formatter(params) {
                        const { axisValue: dateStr } = params[0];
                        const recordList = vm.dayRecords.find((dr) => dr.dateStr == dateStr)?.deviceRecords;
                        let str = `${params[0].name}<br>`;
                        str += 
                        `<div style="display: flex;text-align: left;align-items: center;margin-bottom: 3px">
                                <span style="width: 200px">设备名称</span>
                                <span style="width: 100px">可预约总量</span>
                                <span style="width: 100px">已预约量</span>
                        </div>`
                        recordList.forEach((rl) => {
                            const color = vm.legendList.find(ll => ll.name == rl.deviceClassName)?.color;
                            const styleStr = `display: inline-block;vertical-align: middle;width: 10px;height: 10px;border-radius: 50%;overflow: hidden;`
                            str += 
                            `<div style="display: flex;text-align: left;align-items: center;margin-bottom: 3px">
                                <div style="width: 200px">
                                    <span style="${styleStr};background-color: ${color}"></span>
                                    ${rl.deviceClassName}
                                </div>
                                <div style="width: 100px">${rl.ableNum || 0}</div>
                                <div style="width: 100px">${rl.num || 0}</div>
                            </div>`
                        })
                        return str;
                    }
                }
                this.drawChart();
            })
        })
    }

    drawChart() {
        if (!this.chart) {
            this.chart = echarts.init(this.dayCountChartRef);
        }
        this.$nextTick(() => {
            this.chart.setOption(this.chartOptions, true)
        })
    }

  }
