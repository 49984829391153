
  import { Watch, Component, Vue, Ref, InjectReactive } from "vue-property-decorator";

  import { bookGroupWithTime, listBookStoreByType } from "@/api/booking";

  import { debounce } from "lodash";

  import * as echarts from "echarts";
  import dayjs from "dayjs";
  
  @Component({})
  export default class BookingDayChart extends Vue {

    @InjectReactive() storeList;
    @Ref("dayCountChartRef") dayCountChartRef: HTMLElement;

    chart = null;

    queryForm = {
        storeId: "",
        date: "",
    }

    storeSelectLoading = false;
    storeOptions = [];

    dayCountX = [];
    ableBookList = [];
    recordList = [];

    chartOptions = {
        backgroundColor: 'white',
        grid: {
            top: '15%',
            left: '3%',
            right: '5%',
            bottom: '35',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            borderWidth: 1,
            axisPointer: {
            type: 'shadow'
            },
            extraCssText: 'z-index:2'

        },
        legend: [{
            bottom: "0",
            orient: 'horizontal',
            data: ['已预约量', '可预约量总量' ],
            itemWidth: 30,
            itemHeight: 10,
            itemGap: 15,
            borderRadius: 4,
            textStyle: {
                color: '#000',
                fontFamily: 'Alibaba PuHuiTi',
                fontSize: 14,
                fontWeight: 400
            }
        }],
        xAxis: {
            name: "时间",
            type: 'category',
            nameTextStyle: {
                color: '#999',
                fontSize: 14,
            },
            data: this.dayCountX,
            axisLine: {
            show: false
            },
            axisTick: {
            show: false
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#393939' //X轴文字颜色
                }
            }
        },
        yAxis: [
            {
            type: 'value',
            name: "数量",
            nameTextStyle: {
                color: '#999',
                fontSize: 14,
            },
            nameGap: 30,  // 表现为上下位置
            axisLine: {
                show: true,
                lineStyle: {
                color: '#eeeeee'
                }
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                color: '#393939',
                fontSize: 14
            },
            splitLine: {
                show: true,
                lineStyle: {
                color: '#eeeeee'
                }
            }
            }

        ],
        series: [
            {
                name: '已预约量',
                type: 'line',
                smooth: true,
                showAllSymbol: true, //显示所有图形。
                //标记的图形为实心圆
                symbolSize: 8, //标记的大小
                itemStyle: {
                    //折线拐点标志的样式
                    color: 'white',
                    borderWidth: '2',
                    normal: {
                        color: '#566fc4'//拐点颜色
                    }
                },
                label: {
                    show: true,
                    position: 'top',
                    color: "#f9ca61"
                },
                lineStyle: {
                    color: '#566fc4'
                },
                data: this.recordList,
            },
            {
                name: '可预约量总量',
                type: 'line',
                smooth: true,
                showAllSymbol: true, //显示所有图形。
                symbolSize: 8, //标记的大小
                itemStyle: {
                //折线拐点标志的样式
                color: 'white',
                borderWidth: '2',
                normal: {
                    color: '#f9ca61'//拐点颜色
                }
                },
                label: {
                    show: true,
                    position: 'top',
                    color: "#f9ca61"
                },
                lineStyle: {
                    color: '#f9ca61'
                },
                data: []
            }
        ]
    }

    mounted() {
        this.queryForm.date = dayjs().format("YYYY-MM-DD");
        this.bookGroupWithTime();
    }

    @Watch("queryForm", { deep: true })
    bookGroupWithTime() {
        const params = {
            storeIds: this.queryForm.storeId ? [this.queryForm.storeId] : undefined,
            startDate: this.queryForm.date ? dayjs(this.queryForm.date).format("YYYY-MM-DD 00:00:00") : "",
            endDate: this.queryForm.date ? dayjs(this.queryForm.date).format("YYYY-MM-DD 23:59:59") : "",
        }
        bookGroupWithTime(params).then((res) => {
            const { ableBookList, recordList } = res.data;
            this.chartOptions.xAxis.data = ableBookList.map((el) => el.timeStr);
            this.chartOptions.series[0].data = recordList.map((el) => el.num);
            this.chartOptions.series[1].data = ableBookList.map((el) => el.num);
            this.drawChart();
        })
    }

    drawChart() {
        if (!this.chart) {
            this.chart = echarts.init(this.dayCountChartRef);
        }
        this.$nextTick(() => {
            this.chart.setOption(this.chartOptions, true)
        })
    }

  }
