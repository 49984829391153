
  import { Watch, Component, Vue, Ref, ProvideReactive } from "vue-property-decorator";
  import BookingDayChart from "./components/dayChart.vue";
  import BookingCountChart from "./components/countChart.vue";
  import BookingCountDeviceChart from "./components/countDeviceChart.vue";

  import { listBookStoreByType } from "@/api/booking";
  
  @Component({
    inject: [],
    components: {
      BookingDayChart,
      BookingCountChart,
      BookingCountDeviceChart
    }
  })
  export default class BookingCharts extends Vue {
    @Ref("BookingDayChartRef") BookingDayChartRef !: BookingDayChart;
    @Ref("BookingCountChartRef") BookingCountChartRef !: BookingCountChart;
    @Ref("BookingCountDeviceChartRef") BookingCountDeviceChartRef !: BookingCountChart;

    @ProvideReactive() storeList = [];

    mounted() {
      this.getStoreList();
    }

    getStoreList() {
      listBookStoreByType().then((res) => {
        this.storeList = res.data;
      })
    }

  }
