import { service } from '@/ajax/request';

export const bookRecordList = (params) => {
    return service({
        url: '/book/bookRecord/query',
        method: 'POST',
        params,
    })
}

// 获取设备分类配置
export const bookServiceList = (params = {}) => (
    service({
        url: '/book/bookService/queryByClass',
        method: 'POST',
        params,
    })
)

// 服务配置:编辑服务项
export const bookServiceEdit = (params) => (
    service({
        url: '/book/bookService/editService',
        method: 'POST',
        params,
    })
)

// 获取指定门店配置
export const getStoreConfig = (storeId: number | string) => (
    service({
        url: `/book/bookService/getConfig/${storeId}`,
        method: 'POST',
    })
)
// 保存指定门店配置
export const saveStoreConfig = (params) => (
    service({
        url: `/book/bookService/saveConfig`,
        method: 'POST',
        params
    })
)

// 获取可预约门店列表
export const listBookStoreByType = (params = {}) => (
    service({
        url: `/store/storeDevice/listBookStoreByType`,
        method: 'POST',
        params
    })
)

export const bookGroupWithTime = (params) => {
    return service({
        url: `/book/dashboard/groupWithTime`,
        method: 'POST',
        params
    })
}
export const bookGroupWithDay = (params) => {
    return service({
        url: `/book/dashboard/groupWithDay`,
        method: 'POST',
        params
    })
}
export const bookDeviceClassData = (params) => {
    return service({
        url: `/book/dashboard/deviceClassData`,
        method: 'POST',
        params
    })
}
export const bookDeviceWithDay = (params) => {
    return service({
        url: `/book/dashboard/deviceWithDay`,
        method: 'POST',
        params
    })
}
export const getStoreRestRecords = (params) => {
    return service({
        url: `/store/storeRestRecords/list`,
        method: 'POST',
        params
    })
}
export const addStoreRestRecord = (params) => {
    return service({
        url: `/store/storeRestRecords/save`,
        method: 'POST',
        params
    })
}
export const getRestRecordStoreList = (id) => {
    return service({
        url: `/store/storeRestRecords/getStoresName?id=${id}`,
        method: 'GET',
    })
}
export const delRecordStoreRest = (id) => {
    return service({
        url: `/store/storeRestRecords/delete/${id}`,
        method: 'POST',
    })
}